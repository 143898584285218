// Middleware makes a check before page-load
// If user has not verified with bankid user will
// be redirected away from this page
import {useBankIDStore} from "~/stores/bankID";

export default defineNuxtRouteMiddleware(async () => {
	const bankIDStore = useBankIDStore()
	if (await bankIDStore.checkIfBankIDAuthenticationIsNeeded()) {
		return navigateTo('/')
	}
})
